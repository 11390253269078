import React from 'react';

import '../asset/signin.css';

export default function Login() {
    return (
        <div className="container-fluid LoginContainer">
            <div className="row h-100pc">
                <div className="spContainer m-auto">
                    <div className="card px-4 py-5 border-0 shadow">
                        <div className="d-inline text-left mb-3">
                            <h3 className="font-weight-bold">Login</h3>
                            {/* <p>Access the most popular project management app in indonesia !</p> */}
                        </div>
                        <div className="d-inline text-center mb-3">
                            <div className="form-group mx-auto">
                                <input className="form-control inpSp" type="text" placeholder="username" />
                            </div>
                        </div>
                        <div className="d-inline text-center mb-3">
                            <div className="form-group mx-auto">
                                <input className="form-control inpSp" type="password" placeholder="Password" />
                            </div>
                        </div>
                        <div className="d-inline text-left mb-3">
                            <div className="form-group mx-auto">
                                <button className="btn btn-primary mr-2">Confirm</button>
                                <a className="small text-black-50 pl-2 mr-2 border-left mr-2" href="">Forgot Password ?</a>
                            </div>
                        </div>
                        <div className="d-inline text-left mb-1">
                            <div className="form-group mx-auto mb-0">
                                <label className="text-black-50 small">or login with</label>
                            </div>
                        </div>
                        <div className="d-inline text-left">
                            <div className="form-group mx-auto">
                                <button type="button" className="btn btn-sm btn-light mr-2"> <span className="badge text-white"><i className="fab fa-google text-danger"></i></span>
                                    Google
                                </button>
                                <button type="button" className="btn btn-sm btn-light mr-2"> <span className="badge text-white"><i className="fab fa-facebook-f text-primary"></i></span>
                                    Facebook
                                </button>
                            </div>
                        </div>
                        <div className="d-inline text-left mt-3">
                            <div className="form-group mx-auto mb-0">
                                <a className="text-black font-weight-bold regStr" href="#">Register new account</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
