import React from 'react'

export default function Footer() {
    return (
        <>
            <footer id="footer" className="footer">

                <div className="footer-newsletter">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-12 text-center">
                                <h4>Our Newsletter</h4>
                                <p>Tamen quem nulla quae legam multos aute sint culpa legam noster magna</p>
                            </div>
                            <div className="col-lg-6">
                                <form action="" method="post">
                                    <input type="email" name="email" />
                                    <input type="submit" value="Subscribe" />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-top">
                    <div className="container">
                        <div className="row gy-4">
                            <div className="col-lg-5 col-md-12 footer-info">
                                <a href="index.html" className="logo d-flex align-items-center">
                                    <img src="../asset/FlexStart/assets/img/logo.png" alt="" />
                                    <span>FlexStart</span>
                                </a>
                                <p>Cras fermentum odio eu feugiat lide par naso tierra. Justo eget nada terra videa magna derita valies darta donna mare fermentum iaculis eu non diam phasellus.</p>
                                <div className="social-links mt-3">
                                    <a href="#" className="twitter"><i className="bi bi-twitter"></i></a>
                                    <a href="#" className="facebook"><i className="bi bi-facebook"></i></a>
                                    <a href="#" className="instagram"><i className="bi bi-instagram"></i></a>
                                    <a href="#" className="linkedin"><i className="bi bi-linkedin"></i></a>
                                </div>
                            </div>

                            <div className="col-lg-2 col-6 footer-links">
                                <h4>Useful Links</h4>
                                <ul>
                                    <li><i className="bi bi-chevron-right"></i> <a href="/Home">Home</a></li>
                                    <li><i className="bi bi-chevron-right"></i> <a href="/About">About us</a></li>
                                    <li><i className="bi bi-chevron-right"></i> <a href="/Services">Services</a></li>
                                    <li><i className="bi bi-chevron-right"></i> <a href="/TermsOfService">Terms of service</a></li>
                                    <li><i className="bi bi-chevron-right"></i> <a href="/PrivacyPolicy">Privacy policy</a></li>
                                </ul>
                            </div>

                            <div className="col-lg-2 col-6 footer-links">
                                <h4>Our Services</h4>
                                <ul>
                                    <li><i className="bi bi-chevron-right"></i> <a href="/WebDesign">Web Design</a></li>
                                    <li><i className="bi bi-chevron-right"></i> <a href="/WebDevelopment">Web Development</a></li>
                                    <li><i className="bi bi-chevron-right"></i> <a href="/ProductManagement">Product Management</a></li>
                                    <li><i className="bi bi-chevron-right"></i> <a href="/Marketing">Marketing</a></li>
                                    <li><i className="bi bi-chevron-right"></i> <a href="/GraphicDesign">Graphic Design</a></li>
                                </ul>
                            </div>

                            <div className="col-lg-3 col-md-12 footer-contact text-center text-md-start">
                                <h4>Contact Us</h4>
                                <p>
                                    Majherpara Keshtopur,
                                    <br />Kolkata, West Bengal, IN 700102 <br />
                                    <strong>Phone:</strong> +91 863 7054 652<br />
                                    <strong>Email:</strong> mail@saasproai.com<br />
                                </p>

                            </div>

                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="copyright">
                        &copy; Copyright <strong><span>SaaS Pro AI</span></strong>. All Rights Reserved
                    </div>
                    <div className="credits">


                        Designed by <a href="https://saasproai.com/">SaaS Pro AI</a>
                    </div>
                </div>
            </footer>
            <a href="#" className="back-to-top d-flex align-items-center justify-content-center">
                <i className="bi bi-arrow-up-short"></i>
            </a>
        </>
    )
}
