import React from 'react'

export default function Header({ pagename }) {
  console.log()
    return (
        <header id="header" className="header fixed-top">
            <div className="container-fluid container-xl d-flex align-items-center justify-content-between">

                <a href="index.html" className="logo d-flex align-items-center">
                    <img src="../asset/FlexStart/assets/img/logo.png" alt="" />
                    <span>FlexStart</span>
                </a>

                <nav id="navbar" className="navbar">
                    <ul>
                        <li><a className={pagename == 'Home' ? "nav-link scrollto active": "nav-link scrollto"} href="/Home">Home</a></li>
                        <li><a className={pagename == 'About' ? "nav-link scrollto active": "nav-link scrollto"} href="/About">About</a></li>
                        <li><a className={pagename == 'Services' ? "nav-link scrollto active": "nav-link scrollto"} href="/Services">Services</a></li>
                        <li><a className={pagename == 'Portfolio' ? "nav-link scrollto active": "nav-link scrollto"} href="/Portfolio">Portfolio</a></li>
                        <li><a className={pagename == 'Team' ? "nav-link scrollto active": "nav-link scrollto"} href="/Team">Team</a></li>
                        <li><a className={pagename == 'Blog' ? "nav-link scrollto active": "nav-link scrollto"} href="/Blog">Blog</a></li>
                        <li><a className={pagename == 'Contact' ? "nav-link scrollto active": "nav-link scrollto"} href="/Contact">Contact</a></li>
                        <li><a className="getstarted scrollto" href="/Login">Sign In</a></li>
                        {/* <li className="dropdown"><a href="#"><span>Drop Down</span> <i className="bi bi-chevron-down"></i></a>
                <ul>
                  <li><a href="#">Drop Down 1</a></li>
                  <li className="dropdown"><a href="#"><span>Deep Drop Down</span> <i className="bi bi-chevron-right"></i></a>
                    <ul>
                      <li><a href="#">Deep Drop Down 1</a></li>
                      <li><a href="#">Deep Drop Down 2</a></li>
                      <li><a href="#">Deep Drop Down 3</a></li>
                      <li><a href="#">Deep Drop Down 4</a></li>
                      <li><a href="#">Deep Drop Down 5</a></li>
                    </ul>
                  </li>
                  <li><a href="#">Drop Down 2</a></li>
                  <li><a href="#">Drop Down 3</a></li>
                  <li><a href="#">Drop Down 4</a></li>
                </ul>
              </li> */}

                        {/* <li className="dropdown megamenu"><a href="#"><span>Mega Menu</span> <i className="bi bi-chevron-down"></i></a>
                <ul>
                  <li>
                    <a href="#">Column 1 link 1</a>
                    <a href="#">Column 1 link 2</a>
                    <a href="#">Column 1 link 3</a>
                  </li>
                  <li>
                    <a href="#">Column 2 link 1</a>
                    <a href="#">Column 2 link 2</a>
                    <a href="#">Column 3 link 3</a>
                  </li>
                  <li>
                    <a href="#">Column 3 link 1</a>
                    <a href="#">Column 3 link 2</a>
                    <a href="#">Column 3 link 3</a>
                  </li>
                  <li>
                    <a href="#">Column 4 link 1</a>
                    <a href="#">Column 4 link 2</a>
                    <a href="#">Column 4 link 3</a>
                  </li>
                </ul>
              </li> */}

                    </ul>
                    <i className="bi bi-list mobile-nav-toggle"></i>
                </nav>

            </div>
        </header>
    )
}
