import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import './App.css';
import Home from './pages/Home';
import Contact from './pages/Contact';
import About from './pages/About';
import Portfolio from './pages/Portfolio';
import Services from './pages/Services';
import Team from './pages/Team';
import Price from './pages/Price';
import Blog from './pages/Blog';
import Login from './pages/Login';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="Home" element={<Home />} />
        <Route path="Contact" element={<Contact />} />
        <Route path="About" element={<About />} />
        <Route path="Portfolio" element={<Portfolio />} />
        <Route path="Services" element={<Services />} />
        <Route path="Team" element={<Team />} />
        <Route path="Price" element={<Price />} />
        <Route path="Blog" element={<Blog />} />
        <Route path="Login" element={<Login />} />
      </Routes>
    </Router>
  );
}

export default App;
